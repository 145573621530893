import DollerMoneyPot from "../assets/images/doller-money-pot.webp"

function GameRates({games}) {

    return (
        <section className="section4">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1 className="pb-4 text-center">
                            <img src={DollerMoneyPot} alt="doller" />
                            Game Rates <img src={DollerMoneyPot} alt="doller" />
                        </h1>
                    </div>
                    <div className="col-lg-12 text-center">
                        <div className="row mx-2 mx-lg-0 border p-2 border-warning rounded-4 bg-rate">
                            {
                                games.map((game, index) => {
                                    return <div className="col-lg-4 mb-2" key={index}>
                                        <div className="border-bottom">
                                            <div className="p-2 rounded-4">
                                                <h2 className="fs-4 headline" role="presentation">
                                                    {game.name}
                                                </h2>
                                                <h3 className="fs-4 subhead m-0">1 RS KA {game.rate} Rs</h3>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default GameRates;