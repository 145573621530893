import React, { useEffect, useState } from 'react';
import HeroSubImage from "../assets/media/hero_sub_image.svg"
import Hero from "../assets/media/hero.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faCircleDown, faD, faDownLeftAndUpRightToCenter, faDownload, faPhone } from '@fortawesome/free-solid-svg-icons';
import TopWinners from '../components/TopWinners';
import GameRates from '../components/GameRates';
import Service from '../components/Service';
import AvailableGames from '../components/AvailableGames';
import FAQs from '../components/FAQs';
import SocialIcons from '../components/SocialIcons';
import { generateToken } from '../common/helper';
import { NavLink } from 'react-router-dom';


function Home({data}) {
    

    return (
        <>
            <section
                className="section-1 homescreen"
                style={{ height: "calc(var(--vh, 1vh) * 98)" }}
            >
                <div className="banner__section container w-100">
                    <div className="banner__left ">
                        <div className="banner__title mt-lg-1">
                            <span>Play Games</span>
                            <span>Win Money On</span>
                            <span>
                                Khelo<span className="primary-color">567</span>
                            </span>
                        </div>
                        <div className="banner__stats">
                            <img
                                alt="User Count"
                                id="countUserImg"
                                loading="lazy"
                                width={366}
                                height={36}
                                decoding="async"
                                data-nimg={1}
                                style={{ color: "transparent" }}
                                src={HeroSubImage}
                            />
                        </div>
                    </div>
                    <div className="banner__right">
                        <img
                            className=""
                            alt="Banner"
                            fetchpriority="high"
                            width={1878}
                            height={3839}
                            decoding="async"
                            data-nimg={1}
                            style={{ color: "transparent" }}
                            src={Hero}
                        />
                    </div>
                </div>
                <NavLink
                    className="text-decoration-none display-6 color-primary text-white download-app"
                    href={data?.app_link}
                >
                    Download App  
                    {/* <FontAwesomeIcon icon={faDownload} /> */}

                    <FontAwesomeIcon style={{marginLeft:12}} icon={faCircleDown} />
                </NavLink>
            </section>

            <section id="" className="container">
                <div className="container">
                    <div className="row d-flex align-items-center p-3 p-lg-5">
                        <div className="col-12 col-lg-6 text-center">
                            <h4 className="color-primary">Connect with phone call</h4>
                            <div className="display-5 fw-bolder color-primary">+91 {data.wpNumber}</div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="d-flex justify-content-center align-items-center gap-3 contact-btn">
                                <a
                                    className="d-flex align-items-center text-decoration-none"
                                    style={{ backgroundColor: "#191d31", color: "#fff" }}
                                    href={"tel:+91" + data.wpNumber }
                                >
                                    <FontAwesomeIcon size='lg' className='contact-icons' icon={faPhone} />
                                    Call Now
                                </a>
                                <a
                                    className="d-flex align-items-center text-decoration-none"
                                    style={{ backgroundColor: "#25d366", color: "#fff" }}
                                    href={"tel:+91" + data.wpNumber }
                                >
                                    <FontAwesomeIcon size='xl' className='contact-icons' icon={faWhatsapp} />
                                    WhatsApp
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {
                data?.winners ? 
                    <TopWinners winners={data.winners} />
                : null
            }
            
            {
                data?.games ? 
                    <GameRates games={data.games} />
                : null
            }

            <Service />

            {
                data?.markets ? 
                    <AvailableGames marketsData={data.markets} />
                : null
            }

            <FAQs />

            <SocialIcons wp={data.wpNumber} tele={data.teleNumber} />
        </>
    );
}

export default Home;
