import Play from "../assets/media/Play.svg";
import Star from "../assets/images/star.webp"
import { useEffect, useState } from "react";
import { getCurrentTimeInSeconds, timeToSeconds, to12HourFormat } from "../common/helper";
import { NavLink } from "react-router-dom";

const AvailableGames = ({marketsData}) => {
    const [markets, setMarkets] = useState([]);
    
    useEffect(() => {
        let _markets = marketsData.map(market => {
            const marketOpenSeconds = timeToSeconds(market.market_open_time);
            const openBidLastSeconds = timeToSeconds(market.open_bid_last_time);
            const closeBidLastSeconds = timeToSeconds(market.close_bid_last_time);
            const currentSeconds = getCurrentTimeInSeconds(); 

            let status;
            let isOpenForBidding = false;

            // Determine the market status and bidding flag
            if (currentSeconds >= 10 && currentSeconds < marketOpenSeconds) {
                status = "Running for open";
                isOpenForBidding = true;
            } else if (currentSeconds < marketOpenSeconds || currentSeconds > closeBidLastSeconds) {
                status = "Closed for today";
            } else if (currentSeconds >= marketOpenSeconds && currentSeconds < openBidLastSeconds) {
                status = "Running for open";
                isOpenForBidding = true;
            } else if (currentSeconds >= openBidLastSeconds && currentSeconds < closeBidLastSeconds) {
                status = "Running for close";
                isOpenForBidding = true;
            } else {
                status = "Closed for today"; // Fallback, shouldn't be reached
            }

            const openBidTime12Hour = to12HourFormat(market.open_bid_time);
            const closeBidTime12Hour = to12HourFormat(market.close_bid_last_time);

            // Construct the output object for each market
            return {
                name: market.name,
                openBidTime: openBidTime12Hour,
                closeBidTime: closeBidTime12Hour,
                status: status,
                isOpenForBidding: isOpenForBidding,
                result: market.result
            };
        });
        
        /* _markets = _markets.sort((a, b) => {
            return b.isOpenForBidding - a.isOpenForBidding; // Sort true (1) before false (0)
        }) */

        setMarkets(_markets);
    }, []);
    
    return (
        <section className="container">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="display-7 text-center my-5 fw-bolder color-primary text-sm">
                            <img src={Star} alt="star1" className="mx-lg-2" />
                            Available Games <img src={Star} alt="star2" />{" "}
                        </h2>
                    </div>
                    <div className="col-lg-12">
                        <div className="row gap-3 justify-content-center">
                            {
                                markets.map((m, i) => {
                                    return <div className="gameCard__wrapper" key={i}>
                                        <div className="gameCard__firstSection">
                                            <div className="first__data">
                                                <span className="gameCard__name"> { m.name } </span>
                                                <span className="gameCard__result"> { m.result.formatted_result } </span>
                                                <span
                                                    className="gameCard__marketStatus"
                                                    style={{ color: m.isOpenForBidding ? "#00b200" : "#ff0000" }}
                                                >
                                                    { m.status }
                                                </span>
                                            </div>
                                            <div className={"play__btn " + (m.isOpenForBidding ? "play__btn--animate" : "")}>
                                                <img
                                                    alt="play_btn"
                                                    loading="lazy"
                                                    width={13}
                                                    height={13}
                                                    decoding="async"
                                                    data-nimg={1}
                                                    src={Play}
                                                    style={{
                                                        color: m.isOpenForBidding ? "initial" : "transparent",
                                                        filter: m.isOpenForBidding ? "none" : "grayscale(100%)",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="gameCard__charts">
                                            <NavLink 
                                                to={"/charts/pana/" + m.name + "?scrollToBottom=true" }
                                                className='gameCard__link'
                                            >
                                                Pana Chart
                                            </NavLink>
                                            
                                            <NavLink
                                                to={"/charts/jodi/" + m.name + "?scrollToBottom=true" }
                                                className='gameCard__link'
                                            >
                                                Jodi Chart
                                            </NavLink>
                                        </div>
                                        <div className="result__time">
                                            <span>
                                                Open Bids : <strong> { m.openBidTime } </strong>
                                            </span>
                                            <span>
                                                Close Bids :<strong> { m.closeBidTime } </strong>
                                            </span>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AvailableGames;
