export const winners = [
    {
        name: "Varun Chopra",
        amount: 31000
    },{
        name: "Rajesh Kumar",
        amount: 58200
    },{
        name: "Simran Kaur",
        amount: 65400
    },{
        name: "Manish Patel",
        amount: 23000
    },{
        name: "Preeti Desai",
        amount: 59100
    },{
        name: "Priyanka Sharma",
        amount: 51000
    },{
        name: "Sunita Rao",
        amount: 38000
    },{
        name: "Manan Taneja",
        amount: 25800
    },
];


export const GameAndRates = [
    {
        name: "Single Digit",
        rate: "9.5"
    },{
        name: "Jodi Digits",
        rate: "95"
    },{
        name: "Red Brackets",
        rate: "95"
    },{
        name: "Single Pana",
        rate: "150"
    },{
        name: "Double Pana",
        rate: "300"
    },{
        name: "Triple Pana",
        rate: "900"
    },
];


export const marketData = [
    { name: "SUPREME NIGHT", market_open_time: "10:00:00", open_bid_last_time: "20:40:00", close_bid_last_time: "22:40:00" },
    { name: "KARNATAKA NIGHT", market_open_time: "09:00:00", open_bid_last_time: "18:35:00", close_bid_last_time: "19:35:00" },
    { name: "SURYA MORNING", market_open_time: "08:00:00", open_bid_last_time: "09:00:00", close_bid_last_time: "10:00:00" },
];