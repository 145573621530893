import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const SocialIcons = ({wp, tele}) => {
    return (
        <div className="social_float_icon">
            <a href={"https://t.me/+91" + wp} rel="noreferrer" className="social_btn telegram" target="_blank">
                <FontAwesomeIcon icon={faTelegram} />
            </a>
            <a href={"https://wa.me/+91" + tele} rel="noreferrer" className="social_btn whatsapp" target="_blank">
                <FontAwesomeIcon icon={faWhatsapp} />
            </a>
        </div>
    );
};

export default SocialIcons;
