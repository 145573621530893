import Deposit from "../assets/images/deposit.png";
import Download from "../assets/images/download.png";
import MoneyBag from "../assets/images/money-bag.png";
import ClockTime from "../assets/images/clock-time.png";

const Service = () => {

    return (
        <section className="container service">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3">
                        <div className="s_box">
                            <img
                                width={50}
                                height={50}
                                src={Deposit}
                                alt="card-in-use"
                            />
                            <div className="s-data">
                                <h4 style={{ color: "#fff" }}>₹100</h4>
                                <h5>Minimum Deposit</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="s_box">
                            <img
                                width={50}
                                height={50}
                                src={Download}
                                alt="download"
                            />
                            <div className="s-data">
                                <h4>100K+</h4>
                                <h5>Downloads</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="s_box">
                            <img
                                width={50}
                                height={50}
                                src={ClockTime}
                                alt="clock-time"
                            />
                            <div className="s-data">
                                <h4>Daily</h4>
                                <h5>Fast And Accurate Result</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="s_box">
                            <img
                                width={50}
                                height={50}
                                src={MoneyBag}
                                alt="money-bag"
                            />
                            <div className="s-data">
                                <h4>₹200</h4>
                                <h5>Minimum Withdrawal</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default Service;
