export function timeToSeconds(time) {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return hours * 3600 + minutes * 60 + (seconds || 0);
}

export function getCurrentTimeInSeconds() {
    const now = new Date();
    return now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
}


export function to12HourFormat(time) {
    const [hours, minutes] = time.split(":").map(Number);
    const suffix = hours >= 12 ? "PM" : "AM";
    const hours12 = hours % 12 || 12; // Convert 0 to 12 for midnight

    return `${hours12}:${minutes.toString().padStart(2, "0")} ${suffix}`;
}

export const generateToken = async(secretKey) => {
    const timestamp = Math.floor(Date.now() / 1000); // Current time in seconds
    const salt = Math.random().toString(36).substring(2); // Generate a random salt (nonce)

    // Create the string to hash: timestamp + salt + secret key
    const tokenString = `${timestamp}:${salt}:${secretKey}`;

    // Hash the token string using SHA-256
    const token = await sha256(tokenString);  // Use native SHA-256 hashing

    return { token, timestamp, salt };  // Return token, timestamp, and salt for later validation
};



export const sha256 = (message) => {
    const msgUint8 = new TextEncoder().encode(message); // encode as bytes
    
    return window.crypto.subtle.digest('SHA-256', msgUint8).then((hashBuffer) => {
        // Convert the hash buffer to a hexadecimal string
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
        return hashHex; // Return the hash in hex format
    });
};

export const createCookie = (name, value, hours) => {
    const date = new Date();
    date.setTime(date.getTime() + hours * 60 * 60 * 1000); 
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expires}; path=/`; 
};


export const readCookie = (name) => {
    const cookies = document.cookie.split('; ');
    const cookie = cookies.find((cookie) => cookie.startsWith(`${name}=`));
    
    if (cookie) return cookie.split('=')[1];
  };