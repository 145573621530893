import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';

import './assets/css/App.css';
import './assets/css/Custom.css';

import Home from './pages/Home';
import Charts from './pages/Charts';
import MarketJodiChart from './pages/MarketJodiChart';
import MarketPanaChart from './pages/MarketPanaChart';
import { useEffect, useState } from 'react';
import { generateToken } from './common/helper';

function App() {
  const [data, setData] = useState({});

  const secretKey = process.env.REACT_APP_SECRET_KEY;

  const host = process.env.REACT_APP_API_HOST;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { token, timestamp, salt } = await generateToken(secretKey);

    fetch(host + '/api/index.json', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-Timestamp': timestamp,
        'X-Salt': salt,
        'Accept': 'application/json'
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.log("Error => ", error)
      });
  }

  return (
    <Router>
      <div>
        <Header link={data?.app_link} />

        <Routes>
          <Route path="/" exact element={<Home data={data} />} />
          <Route path="/charts" exact element={<Charts />} />
          <Route path="/charts/jodi/:id" exact element={<MarketJodiChart />} />
          <Route path="/charts/pana/:id" exact element={<MarketPanaChart />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
