import Star from "../assets/images/star.webp"
import { Disclosure } from '@headlessui/react';

const FAQs = () => {
    return (
        <section id="faqs" className="faqSection">
            <div className="container">
                <div className="row my-lg-5 py-lg-5 my-4">
                    <div className="col-lg-12">
                        <h2 className="display-7 text-center mb-4 pb-lg-3 mb-lg-5 fw-bolder color-primary ">
                            <img
                                alt="star1"
                                loading="lazy"
                                width={32}
                                height={32}
                                decoding="async"
                                src={Star}
                            />{" "}
                            Frequently Asked Questions
                            <img
                                alt="star2"
                                className="test-img"
                                loading="lazy"
                                width={32}
                                height={32}
                                decoding="async"
                                src={Star}
                            />
                        </h2>
                    </div>
                    <div className="col-lg-12">
                        <div className="accordion" id="faqAccordion">
                            {[
                                { 
                                    question: "What is Khelo567?", 
                                    answer: "Khelo567 Is A Satta Matka Gaming App Where You Can Play Games And Win Jackpot." 
                                },{ 
                                    question: "What games are available on Khelo567?", 
                                    answer: "Popular Satta Matka Games Like Kalyan, Sridevi, Milan, Time Bazar & Rajdhani." 
                                },{ 
                                    question: "Does Khelo567 have License ?", 
                                    answer: "Yes, Khelo567 Have Sub License In Malta. All Rights That Allows To Operate Software Worldwide." 
                                },{ 
                                    question: "What is minimum Deposit and Withdrawal Amount ?", 
                                    answer: "In Khelo567, We Allow Minimum of 100.00 Rs/- Deposit and 200.00 Rs/- Withdrawals." 
                                },{ 
                                    question: "Why to Choose Khelo567 Among Other Providers ?", 
                                    answer: "Khelo567 Is The Most Trusted Gaming Provider Worldwide." 
                                },
                                
                            ].map((item, index) => (
                                <Disclosure key={index}>
                                    {({ open }) => (
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id={`heading${index}`}>
                                                <Disclosure.Button
                                                    className={`accordion-button ${open ? '' : 'collapsed'}`}
                                                    aria-expanded={open}
                                                >
                                                    {item.question}
                                                </Disclosure.Button>
                                            </h2>
                                            <Disclosure.Panel
                                                id={`collapse${index}`}
                                                className={`accordion-collapse collapse ${open ? 'show' : ''}`}
                                                aria-labelledby={`heading${index}`}
                                            >
                                                <div className="accordion-body">{item.answer}</div>
                                            </Disclosure.Panel>
                                        </div>
                                    )}
                                </Disclosure>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default FAQs;
