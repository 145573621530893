import { useEffect, useState } from "react";
import { createCookie, generateToken, readCookie } from "../common/helper";
import { NavLink } from "react-router-dom";

function Charts() {

    const [markets, setMarkets] = useState([]);

    useEffect(() => {
        let _markets = readCookie("khelo567-chart-markets");

        if (_markets) {
            _markets = JSON.parse(_markets);
            setMarkets(_markets);
        } else {
            fetchData();
        }
    }, []);

    const fetchData = async () => {
        const secretKey = process.env.REACT_APP_SECRET_KEY;

        const host = process.env.REACT_APP_API_HOST;

        const { token, timestamp, salt } = await generateToken(secretKey);

        fetch(host + '/api/markets.json', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'X-Timestamp': timestamp,
                'X-Salt': salt,
                'Accept': 'application/json'
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                return response.json();
            })
            .then((data) => {
                setMarkets(data);
                createCookie('khelo567-chart-markets', JSON.stringify(data),1);
            })
            .catch((error) => {
                console.log("Error => ", error)
            });
    }

    return (
        <main className="container chart-wrapper">
            <h2 className="h2"> Jodi Charts </h2>
            <section>
                {
                    markets.length > 1 ?
                        markets.map((m, i) => {
                            return <div className="col-xl-12 col-md-12 w-100 mb-1" key={i}>
                                <div className="shadow card rounded border-0 timetable">
                                    <div className="card-body" style={{ textAlign: "center" }}>
                                        <NavLink
                                            to={"/charts/jodi/" + m.name}
                                            className="chart-link"
                                            style={{ transition: "all .3s" }}
                                        >
                                            <h4 className="market-name"> {m.name} </h4>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        }) : null
                }
            </section>
            
            
            
            <h2 className="h2 mt-5"> Pana Charts </h2>
            <section>
                {
                    markets.length > 1 ?
                        markets.map((m, i) => {
                            return <div className="col-xl-12 col-md-12 w-100 mb-1" key={i}>
                                <div className="shadow card rounded border-0 timetable">
                                    <div className="card-body" style={{ textAlign: "center" }}>
                                        <NavLink
                                            style={{ transition: "all .3s" }}
                                            className="chart-link"
                                            to={"/charts/pana/" + m.name}
                                        >
                                            <h4 className="market-name"> {m.name} </h4>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        }) : null
                }
            </section>
        </main>

    );
}


export default Charts;