import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { generateToken } from "../common/helper";
import { NavLink } from "react-router-dom";

function MarketPanaChart() {
    const [data, setData] = useState([]);
    const params = useParams();
    const RED = ['00','05','11','16','22','27','33','38','44','49','50','55','61','66','72','77','83','88','94','99'];

    useEffect(() => {
            fetchData();
    },[]);

    useEffect(() => {
        if (Object.keys(data).length > 0 && window.location.search.includes("scrollToBottom=true")) {
            window.scrollTo(0, document.body.scrollHeight)
        }
    },[data]);

    const fetchData = async () => {
        const secretKey = process.env.REACT_APP_SECRET_KEY;

        const host = process.env.REACT_APP_API_HOST;

        const { token, timestamp, salt } = await generateToken(secretKey);

        fetch(host + '/api/market-chart.json', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'X-Timestamp': timestamp,
                'X-Salt': salt,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id: params.id, type: 'pana' })
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                return response.json();
            })
            .then((data) => {
                setData(data);
            })
            .catch((error) => {
                console.log("Error => ", error)
            });
    }

    return (
        <main className="container chart-wrapper">
            <h2 className="h2"> {params.id} Pana Chart </h2>
            <p className="desc"> {params.id} Pana Chart Satta Matka Record Old History Historical Data Bracket Results Chart Online Live Book Digits Numbers</p>

            <div className="scroll-down-button-wrapper">
                <button className="button__wrapper" onClick={() => window.scrollTo(0, document.body.scrollHeight)} id="scroll-down-button">Scroll Down</button>
            </div>

            <div className="table-responsive d-flex justify-content-center align-items-center">
                <table className="table w-75" border="collapse">
                    <thead>
                        <tr>
                            <th className="ch">DATE</th>
                            <th className="ch">MON</th>
                            <th className="ch">TUE</th>
                            <th className="ch">WED</th>
                            <th className="ch">THU</th>
                            <th className="ch">FRI</th>
                            <th className="ch">SAT</th>
                            <th className="ch">SUN</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.entries(data).map(([dateRange, days], i) => {
                                let dates = dateRange.split(" to ")

                                return <tr key={i}>
                                    <td className="cc"> <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            alignItems: "center"
                                        }}
                                    >
                                        <span className="result_date"> {dates[0]} </span>
                                        <span className="result_date">to</span>
                                        <span className="result_date"> {dates[1]} </span>
                                    </div> </td>

                                    {
                                        days.map((d, _i) => {
                                            
                                            return <td className="cc" key={_i}> <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    color: RED.includes(d?.result?.open_digit + d?.result?.close_digit) ? "red" : "black"
                                                }}
                                            >
                                                <span className="cp pana"> {d.result.open_pana} </span>
                                                <span className="cp pana-jodi"> {d.result.open_digit}{d.result.close_digit} </span>
                                                <span className="cp pana">{d.result.close_pana}</span>
                                            </div> </td>
                                        })
                                    }
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>


            <div className="charts-btb-blk">
                <div className="back-btn">
                    <NavLink
                        to={'/charts'}
                    >
                        <button className="button__wrapper chart__btn">Back</button>
                    </NavLink>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <button className="button__wrapper chart__btn" id="scroll-top-button"
                        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    >
                        Scroll to Top
                    </button>
                </div>
            </div>

        </main>

    );
}


export default MarketPanaChart;