import React, { useState } from 'react';
import ImageLogo from "../../src/assets/images/Khelo567-full.png"
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBurger } from '@fortawesome/free-solid-svg-icons';

function Header({link}) {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
      };
    
    return (
        <nav className="navbar navbar-expand-lg pt-3 navbar-light justify-content-between custom__nav">
            <NavLink className="navbar-brand" to="/" onClick={() => navigate('/')}>
                <img
                    alt="logo"
                    fetchpriority="high"
                    width={200}
                    height={50}
                    decoding="async"
                    data-nimg={1}
                    className="topbar-logo"
                    style={{ color: "transparent" }}
                    src={ImageLogo}
                />
            </NavLink>
            <button
                className={"navbar-toggler" + ((!isMenuOpen) ? " collapsed" : "")}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => toggleMenu()}
            >
                <FontAwesomeIcon icon={faBars} />
            </button>
            <div
                className={"collapse navbar-collapse flex-grow-0 header-link" + (isMenuOpen ? " show" : "")}
                id="navbarSupportedContent"
            >
                <ul className="navbar-nav d-flex gap-2 justify-content-center w-100 mt-lg-0 mt-3 mb-lg-0">
                    <li className="nav-item">
                        <NavLink 
                            to={"/"}
                            className={({ isActive }) => (isActive ? 'active-link nav-link menu-link fw-normal' : 'nav-link menu-link fw-normal')}
                        >
                            Home
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink 
                                to={"/charts"}
                                className={({ isActive }) => (isActive ? 'active-link nav-link menu-link fw-normal' : 'nav-link menu-link fw-normal')}
                            >
                            Charts
                        </NavLink>
                    </li>

                    <li id="getAppNavLink" className="nav-item">
                        <a
                            style={{ color: "white" }}
                            className="nav-link mobile-downloadLink"
                            href={link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Get Free App
                        </a>
                    </li>
                </ul>
            </div>
            <a
                target="_blank"
                rel="noreferrer"
                className="mt-4 mt-lg-0 d-lg-block d-none button__app__wrapper"
                href={link}
            >
                <span className="button__app">Get Free App</span>
                <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7 17L17 7M17 7H8M17 7V16"
                        stroke="#fff"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </a>
        </nav>

    );
}

export default Header;
