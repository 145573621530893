import { winners } from "../common/constants";
import 'swiper/css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

function TopWinners() {

    return (
        <div className="container top-winners">
            <h1 className="text-center heading">⭐️ Today's Top Winners ⭐️</h1>
            <Swiper
                loop={true}
                spaceBetween={20}
                centeredSlides={true}
                autoplay={{
                    delay: 0,
                    disableOnInteraction: false,
                }}
                speed={3000}
                // navigation={true}
                slidesPerView={"auto"}
                modules={[Autoplay]}
                className="mySwiper"
            >
                {
                    winners.map((winner, index) => {
                        return <SwiperSlide key={index}>
                                <h3>{winner.name}</h3>
                                <h1>₹{winner.amount}/-</h1>
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </div>
    );
}

export default TopWinners;