import GampbleAware from "../assets/media/gambleaware.svg"
import GampblingTherapi from "../assets/media/gamblingtherapy.svg"
import LogoWhite from "../assets/images/Khelo567-logo-white.jpg"

const Footer = () => {
    return (
        <footer className="bg-footer-theme overflow-hidden">
            <div
                style={{
                    padding: "1rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem"
                }}
                className="container text-center"
            >
                <div
                    style={{
                        display: "flex",
                        gap: "1rem",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <img
                        alt="main_logo"
                        loading="lazy"
                        width={200}
                        height={50}
                        decoding="async"
                        data-nimg={1}
                        style={{ color: "transparent" }}
                        src={LogoWhite}
                    />
                </div>
                <div>
                    <div>
                        <p className="text-white">
                            Players need to be 18+ in order to register. Underage gambling is
                            prohibited.
                        </p>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            width: "100%"
                        }}
                    >
                        <div
                            style={{
                                position: "relative",
                                overflow: "hidden",
                                zIndex: 1,
                                display: "inline-flex",
                                width: "45%",
                                height: "auto",
                                justifyContent: "center"
                            }}
                        >
                            <a href="https://www.begambleaware.org">
                                <img
                                    alt="gamble_aware"
                                    loading="lazy"
                                    width={426}
                                    height={140}
                                    decoding="async"
                                    data-nimg={1}
                                    style={{
                                        color: "transparent",
                                        objectFit: "contain",
                                        width: "100%",
                                        position: "relative",
                                        height: "100%",
                                        maxWidth: 400
                                    }}
                                    src={GampbleAware}
                                />
                            </a>
                        </div>
                        <div
                            style={{
                                position: "relative",
                                overflow: "hidden",
                                zIndex: 1,
                                display: "inline-flex",
                                width: "45%",
                                height: "auto",
                                justifyContent: "center"
                            }}
                        >
                            <a href="https://www.gamblingtherapy.org/">
                                <img
                                    alt="gambling_therapy"
                                    loading="lazy"
                                    width={949}
                                    height={356}
                                    decoding="async"
                                    data-nimg={1}
                                    style={{
                                        color: "transparent",
                                        objectFit: "contain",
                                        width: "100%",
                                        position: "relative",
                                        height: "100%",
                                        maxWidth: 400
                                    }}
                                    src={GampblingTherapi}
                                />
                            </a>
                        </div>
                    </div>
                    <div>
                        <p className="text-white">
                            Our website is operated by Khelo567 International, a company
                            established under the law of Malta, with registered address 3 St.
                            Patrick's, II-Belt Valletta, Malta, and having its gaming sublicense
                            issued by Malta e-Gaming Authority and all rights to operate the
                            gaming software worldwide.
                        </p>
                    </div>
                    <div
                        style={{
                            width: "100%",
                            height: "0.5px",
                            backgroundColor: "#efefef",
                            opacity: "0.5"
                        }}
                    />
                    <div>
                        <p className="text-white">
                            Copyright © 2024 - Khelo567. All Rights Reserved
                        </p>
                    </div>
                </div>
            </div>
        </footer>

    );
};

export default Footer;
